<template>
  <div class="main-container">
    <div class="playground" ref="playground">
      <div class="animation-container" ref="animationRef">
        <div class="list" ref="listRef">
          <div
            class="list-item"
            :data-order="orderMap[index]"
            v-for="(item, index) in list"
            :key="index"
            :class="[
              index == 6 || index == 13 ? 'mr-0' : '',
              index > 6 ? 'mt-100' : '',
            ]"
          >
            <img :src="item.url" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          url: "https://img.alicdn.com/imgextra/i3/O1CN01TVWH501KYCEaUxPgv_!!6000000001175-0-tps-480-480.jpg",
        },
        {
          url: "https://img.alicdn.com/imgextra/i1/O1CN01vqBxIP1L5d98G9xJD_!!6000000001248-0-tps-480-480.jpg",
        },
        {
          url: "https://img.alicdn.com/imgextra/i3/O1CN01AKJU3T1tSi2NAFHFP_!!6000000005901-0-tps-480-480.jpg",
        },
        {
          url: "https://img.alicdn.com/imgextra/i3/O1CN01AKJU3T1tSi2NAFHFP_!!6000000005901-0-tps-480-480.jpg",
        },
        {
          url: "	https://img.alicdn.com/imgextra/i1/O1CN01sphiAp1Fj7bOKEppz_!!6000000000522-2-tps-480-480.png",
        },
        {
          url: "https://img.alicdn.com/imgextra/i2/O1CN015T9BdZ28Ns5n1A82W_!!6000000007921-0-tps-480-480.jpg",
        },
        {
          url: "	https://img.alicdn.com/imgextra/i3/O1CN01bc6FKX1OCuHkDC2J7_!!6000000001670-0-tps-480-480.jpg",
        },
        {
          url: "https://img.alicdn.com/imgextra/i1/O1CN01Y936lS1whluhvos2E_!!6000000006340-0-tps-480-480.jpg",
        },
        {
          url: "https://img.alicdn.com/imgextra/i3/O1CN017Wk2Cp1lp8VaSd22U_!!6000000004867-0-tps-480-480.jpg",
        },
        {
          url: "https://img.alicdn.com/imgextra/i4/O1CN016JIoXg1lMHYbmErdR_!!6000000004804-0-tps-240-240.jpg",
        },
        {
          url: "https://img.alicdn.com/imgextra/i3/O1CN011GyvMe1of0bAveV4u_!!6000000005251-0-tps-480-480.jpg",
        },
        {
          url: "https://img.alicdn.com/imgextra/i2/O1CN01zFSNcP26wYrM09A4S_!!6000000007726-0-tps-480-480.jpg",
        },
        {
          url: "https://img.alicdn.com/imgextra/i2/O1CN0142F9wc23s261dItxf_!!6000000007310-0-tps-480-480.jpg",
        },
        {
          url: "https://img.alicdn.com/imgextra/i4/O1CN012wi8vZ1xt3HbO0ttd_!!6000000006500-0-tps-480-480.jpg",
        },
      ],

      orderMap: {
        0: 0,
        1: 1,
        2: 2,
        3: 3,
        4: 2,
        5: 1,
        6: 0,
        7: 0,
        8: 1,
        9: 2,
        10: 3,
        11: 2,
        12: 1,
        13: 0,
      },

      animationMap: new Map(),
    };
  },
  mounted() {
    this.updateMap()
    this.updateStyles()

    window.addEventListener("scroll", this.updateStyles);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.updateStyles)
  },
  methods: {
    createAnimation(scrollStart, scrollEnd, valueStart, valueEnd) {
      return function (scroll) {
        if (scroll <= scrollStart) {
          return valueStart;
        }
        if (scroll >= scrollEnd) {
          return valueEnd;
        }
        return (
          valueStart +
          ((valueEnd - valueStart) * (scroll - scrollStart)) /
            (scrollEnd - scrollStart)
        );
      };
    },
    getDomAnimation(scrollStart, scrollEnd, dom) {
      scrollStart = scrollStart + dom.dataset.order * 600;
      const opacityAnimation = this.createAnimation(
        scrollStart,
        scrollEnd,
        0,
        1
      );
      const opacity = function (scroll) {
        return opacityAnimation(scroll);
      };

      const xAnimation = this.createAnimation(
        scrollStart,scrollEnd,
        this.$refs.animationRef.clientWidth / 2 -
          dom.offsetLeft -
          dom.clientWidth / 2,
        0
      );
      const yAnimation = this.createAnimation(
        scrollStart,scrollEnd,
        this.$refs.animationRef.clientHeight / 2 -
          dom.offsetTop -
          dom.clientHeight / 2,
        0
      );

      const scaleAnimation = this.createAnimation(
        scrollStart,
        scrollEnd,
        0.5,
        1
      );

      const transform = function (scroll) {
        return `translate(${xAnimation(scroll)}px, ${yAnimation(
          scroll
        )}px) scale(${scaleAnimation(scroll)})`;
      };

      return { opacity, transform };
    },
    updateMap() {
      this.animationMap.clear();
      const playgroundRect = this.$refs.playground.getBoundingClientRect();
      const scrollStart = playgroundRect.top + window.scrollY;
      const scrollEnd = 
        (playgroundRect.bottom + window.scrollY + window.innerHeight) / 2;

      for (const item of this.$refs.listRef.children) {
        this.animationMap.set(
          item,
          this.getDomAnimation(scrollStart, scrollEnd, item)
        );
      }
    },
    updateStyles() {
      const scroll = window.scrollY;
      for (let [dom, value] of this.animationMap) {
        for (const cssProp in value) {
          dom.style[cssProp] = value[cssProp](scroll);
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.playground {
    margin-top: 10px;
  width: 100%;
  height: 3000px;
  .animation-container {
    position: sticky;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: #040506;
    height: 960px;
    .list {
      display: flex;
      flex-wrap: wrap;
      width: 1200px;
      height: 460px;
      background: #15171b;
      border-radius: 20px;
      padding: 100px;
      box-sizing: border-box;
    }
    .list-item {
      width: 80px;
      height: 80px;
      margin-right: 73.333px;
      img {
        display: block;
        width: 80px;
        height: 80px;
      }
    }
    .mr-0 {
      margin-right: 0;
    }
    .mt-100 {
      margin-top: 100px;
    }
  }
}
</style>